import { call, put, takeLatest } from 'redux-saga/effects';
import { downloadFile } from 'helpers/common';
import {
  createOrUpdateEmployeeRewardRequest,
  downloadEmployeeRewardsXlsx,
  getEmployeeRewardsRequest,
} from 'modules/SalaryModule/RewardsTable/services';
import {
  DOWNLOAD_EMPLOYEES_REWARDS_XLSX,
  GET_EMPLOYEE_REWARDS,
  setEmployeeRewardItem,
  setEmployeeRewards,
  setEmployeeRewardsStatus,
  UPSERT_EMPLOYEE_REWARD,
  GET_EMPLOYEE_REWARDS_NEXT_PAGE,
} from 'modules/SalaryModule/RewardsTable/actions';
import { fetchEmployeesError } from 'modules/SalaryModule/Employees/actions';
import { getCsvDownloadQuery } from 'modules/SalaryModule/Employees/utils';
import { setRewardsFamily } from 'modules/SalaryModule/Rewards/actions';
import {
  getCompanyRewardsRequest,
  getRewardsFamilyItemsRequest,
} from 'modules/SalaryModule/Rewards/services';
import { STATUS_ERROR } from 'const';
import { REWARDS_GENERAL_TAB } from 'const/SalaryRewardsPage';

function* getEmployeeRewardsWorker({ payload }) {
  try {
    const { companyId, rewardFamilyId, year, month, page } = payload;
    /* Download family rewards for each month, because rewards number may be different */
    if (rewardFamilyId !== REWARDS_GENERAL_TAB.ID) {
      const { data: family } = yield call(getRewardsFamilyItemsRequest, {
        companyId,
        familyId: rewardFamilyId,
        year,
        month,
        page,
      });
      yield put(setRewardsFamily(family.rewardFamily));
    } else {
      const { data } = yield call(getCompanyRewardsRequest, {
        companyId,
        query: { year, month, limit: 1000 },
      });
      yield put(
        setRewardsFamily({
          companyRewards: data.companyRewards,
          companyDosId: companyId,
          title: REWARDS_GENERAL_TAB.TITLE,
          id: REWARDS_GENERAL_TAB.ID,
        })
      );
    }
    const data = yield call(getEmployeeRewardsRequest, payload);
    yield put(setEmployeeRewards(data));
  } catch (error) {
    yield put(setEmployeeRewardsStatus(STATUS_ERROR));
  }
}
/*
 * Worker for create or update employee reward value
 * */
function* upsertEmployeeRewardWorker({ payload }) {
  try {
    const { data } = yield call(createOrUpdateEmployeeRewardRequest, payload);

    if (data) {
      yield put(setEmployeeRewardItem(data.employeeReward));
    } else {
      const zeroReward = {
        companyRewardId: payload.data.companyRewardId,
        date: payload.data.date,
        employeeId: payload.data.employeeId,
        value: 0,
      };

      yield put(setEmployeeRewardItem(zeroReward));
    }
  } catch (error) {
    yield put(setEmployeeRewardsStatus(STATUS_ERROR));
  }
}

function* downloadEmployeeRewardsXlsxWorker({ payload }) {
  try {
    const { companyId, filter } = payload;
    const query = getCsvDownloadQuery(filter);

    const { fileData, fileName } = yield call(downloadEmployeeRewardsXlsx, {
      companyId,
      query,
    });

    yield call(downloadFile, fileData, fileName);
  } catch (error) {
    console.warn(error);
    yield put(fetchEmployeesError({ error }));
  }
}

export function* rewardsTableMainSaga() {
  yield takeLatest(GET_EMPLOYEE_REWARDS, getEmployeeRewardsWorker);
  yield takeLatest(GET_EMPLOYEE_REWARDS_NEXT_PAGE, getEmployeeRewardsWorker);
  yield takeLatest(UPSERT_EMPLOYEE_REWARD, upsertEmployeeRewardWorker);
  yield takeLatest(
    DOWNLOAD_EMPLOYEES_REWARDS_XLSX,
    downloadEmployeeRewardsXlsxWorker
  );
}
