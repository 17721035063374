export const LAST_NEWS_TRANSLATIONS = {
  TITLE: 'Dernières actualités',
  VIEW_ALL_NEWS: 'Voir toutes les actualités',
  ALL_NEWS: 'Toutes les actualités',
};

export const NEWS = {
  TITLE: 'Actualités',
  CATEGORIES: 'Catégories:',
};
